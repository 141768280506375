import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import PackagesIntro from "../components/Index/PackagesIntro"
import OurPackages from "../components/Index/OurPackages"
import AboutUs from "../components/Index/AboutUs"
import InfoBlocks from "../components/Index/InfoBlocks"
import OurBenefits from "../components/Index/OurBenefits"
import AboutUs2 from "../components/Index/AboutUs2"
import ClientFeedback from "../components/Index/ClientFeedback"
import SEO from "../components/App/SEO"
import CustomerSupportIndex from "../components/Index/CustomerSupportIndex"
import FreeHostingHome from "../components/Index/FreeHostingHome";
import HowToBuyWebHosting from "../components/Index/HowToBuyWebHosting";
import SSLFaqs from "../components/Index/SSLFaqs";
import SecurityAndBackUp from "../components/Index/SecurityAndBackUp";
import LeftTextContentBlock from "../components/Common/LeftTextContentBlock";
import RegisterDomainsHome from "../components/RegisterDomains/RegisterDomainsHome";


const Home = () => {
    return (
        <Layout>

            <SEO title="Găzduire Web Performantă - Hosting Romania | Sitebunker - Server VPS"
                 description="Descoperă o experiență unică de găzduire web prin Sitebunker.ro. Beneficiezi de servicii hosting România cu suport tehnic inclus și securitate performantă."
            />
            <Navbar/>
            <RegisterDomainsHome/>
            <PackagesIntro/>
            <OurPackages/>
            <Banner/>


            <LeftTextContentBlock title='Ce înseamnă Găzduire Web Premium?'
                                  text1="O găzduire web este modul prin care tu acorzi afacerii tale un loc garantat, public, pe internet. Cu ajutorul unui serviciu de găzduire web sau hosting, website-ul afacerii tale poate fi accesat în mediul online, poate fi vizitat și astfel, vei putea să comunici cu toți utilizatorii tăi într-un mod mult mai eficient. Găzduirea web este un serviciu folosit atât de companii, cât și de persoane fizice."
                                  text2="Acest serviciu poate fi de mai multe tipuri în funcție de nevoile clientului. Pentru că luăm foarte în serios fiecare aspect al pachetelor noastre de servicii, chiar și pentru cele mai mici pachete de servicii oferim un set de resurse care ar putea fi încadrate drept suficiente pentru orice website de anvergură mică."/>


            <LeftTextContentBlock title='Găzduire Web fără limitări'
                                  text1='Există și o formă de găzduire care poate fi accesată gratuit. Această găzduire este oferită de servicii precum WordPress sau Blogspot. Fiind un serviciu gratuit, este recomandat doar pentru cei care nu intenționează să productifice platforma online. Limitările unui serviciu de găzduire gratuit nu te vor putea lăsa să îți deschizi magazine online, să construiești platforme de prestari servicii, să oferi utilizatorilor posibilitatea de a se înregistra, etc.'
                                  text2='Un serviciu de găzduire gratuit te va obliga să porți un watermark în domeniul web, ceea ce nu este recomandat absolut deloc pentru persoanele care își doresc să construiască o afacere. Domeniul tău va arăta astfel: www.domeniu.wordpress.com. Acest lucru va semnala potențialilor tăi clienți că website-ul tău nu a putut investi câțiva euro pentru o găzduire web în adevăratul sens al cuvântului. Un website cu o găzduire web gratuită nu inspiră încredere.'/>


            <LeftTextContentBlock title='Găzduirea web premium'
                                  text1="Îți oferă toate resursele de care ai nevoie pentru a putea administra o afacere mică, medie sau mare și nu vei simți niciodată că ești limitat. Dacă afacerea ta continuă să se dezvolte, vei putea să suplimentezi resursele găzduirii și să asiguri mereu utilizatorilor tăi un user experience optim."
                                  text2=" Website-ul tău se va încărca rapid, datele vor fi în siguranță, regulile de GDPR vor fi respectate, iar echipa de la care ai cumpărat pachetul de găzduire web te va ajuta mereu să treci peste problemele tehnice."/>


            <LeftTextContentBlock title='De ce să alegi găzduire web prin Litespeed?'
                                  text1="Serviciile de găzduire standard folosesc Web Servers Apache, care până într-un punct au fost foarte eficiente. Doar că Sitebunker își dorește să ofere mereu servicii superioare și astfel, acum vei beneficia de găzduire web doar pe servere Litespeed."
                                  text2="Aceste servere îți vor aduce un set de beneficii extrem de importante pentru activitatea afacerii tale, dar și pentru user experience-ul utilizatorilor tăi. Litespeed sunt servere web mult mai rapide, te pot ajuta să crești performanțele platformei tale online și sunt mai compatibile cu tot ceea ce înseamna PHP."
                                  text3="Dacă plănuiești să dezvolți o platformă online sau afacerea ta este guvernată deja de un website în Drupal, WordPress, Prestashop, Magento sau Joomla, nivelul de securitate și eficiența vor crește exponențial cu ajutorul unui server Litespeed."/>

            <LeftTextContentBlock title="Pentru cine este potrivită găzduirea web pe Litespeed?"
                                  text2="Dacă ai nevoie de un website care să te ajute să rulezi campanii de marketing, să creezi landing pages, să poți susține un număr foarte mare de persoane în același timp pe website și să poți menține tot acest ansamblu la un nivel optim de securitate, vei avea nevoie de găzduire web prin servere Litespeed. Un astfel de server este cu mult mai eficient în momentul în care website-ul tău este victima unui atac DDOS."
                                  text3="Prin comparație cu serverele clasice, Litespeed se comportă mult mai bine atunci când numărul de utilizatori este exponențial mai mare și reușește să gestioneze cu succes toate resursele pachetului de găzduire web."/>


            <LeftTextContentBlock title="Ce înseamnă migrare gratuită fără griji?"
                                  text1="Migrarea website-ului de la alt provider"
                                  text2="Indiferent de situația utilizatorului care alege să își găzduiască website-ul la Sitebunker, acesta va beneficia de tot suportul pentru ca noi să ne asigurăm că platforma funcționează, ca este securizată și că afacerea nu are nimic de pierdut în urma unei eventuale migrări."
                                  text3="Ce înseamnă o migrare a website-ului? Majoritatea website-urilor care sunt nevoite să treacă prin acest proces de migrare au în comun un factor foarte important. Fiecare dintre ele au ales un pachet de găzduire web care a fost insuficient pentru nevoile afacerii. Tu poți evita această greșeală doar dacă te vei gândi la viitorul afacerii tale. Crezi că numărul de utilizatori prezenți pe website va crește în următorii ani? Crezi că magazinul tău online va avea nevoie de mai multe resurse pentru a putea gestiona mii de produse, mii de pagini de conținut și sute de clienți pe minut?"
                                  text4="Atunci alege un serviciu de găzduire de la noi, pentru că vei putea oricând să faci un upgrade și să primești din ce în ce mai multe resurse, fără a fi nevoit să faci o migrare. Dacă ai nevoie să îți migrezi website-ul de la un alt provider către noi, tot procesul este gratuit. Migrarea este făcută în condiții foarte sigure, informațiile din website nu se pierd, toată platforma ta va rămâne intactă. Tot ceea ce se va întâmpla este că vei schimba providerul de hosting web."
                                  text5="Nu mai aștepta ca momentul 0 să provoace iminența migrării website-ului. Migrează acum și vei preveni o serie lungă de probleme și dificultăți."/>

            <LeftTextContentBlock title='Informații utile despre găzduire web la Sitebunker'
                                  uList={['Echipa noastră te poate ajuta să identifici pachetul potrivit de găzduire pentru afacerea ta.',
                                      'Primești suport tehnic la orice oră din zi sau noapte, 24/7.',
                                      'Comunicarea se poate realiza în mod direct cu un Sysadmin din echipa noastră.',
                                      'Fiecare pachet îți oferă domeniu, protecție, securitate, servere de calitate, backup, SSH, Stocare prin SSD NVMe.',
                                      'Poți alege modul de găzduire prin Servere VPS sau chiar servere Dedicate.',
                                      'Oferim pachete custom pentru afaceri și întreprinderi.',
                                      'Fiecare pachet de găzduire web are SSL inclus.']}
            />

            <HowToBuyWebHosting/>

            <SSLFaqs/>

            <AboutUs/>


            <div className="container">
                <h2 className="h3-title font-weight-bolder">Diferențe dintre Găzduire Web și Găzduirea Premium</h2>
            </div>

            <LeftTextContentBlock title='Găzduire WordPress'
                                  text1='WordPress este una dintre cele mai populare platforme pentru dezvoltarea website-urilor. Fiecare website de prezentare sau magazin online care decide să funcționeze cu ajutorul plugin-ului WooCommerce, poate primi acum un boost de eficiență cu ajutorul unui pachet de găzduire web dedicat proiectelor dezvoltate pe WordPress. Absolut toate pachetele de găzduire Wordpress vor fi securizate prin Imunify360.'
                                  text2='Imunify360 este o un conglomerat de sisteme de securitate organizate pe mai multe niveluri pentru a putea detecta, șterge și oferi protecție împotriva oricărui malware. Este un sistem eficient care garantează că pachetul tău de găzduire îți și transformă website-ul într-un bunker impenetrabil. La toate acestea se mai adaugă și protecția DDoS oferită prin intermediul celor de la Voxility și Arbor.'
                                  text3='Așadar, orice pachet de găzduire web WordPress se va comporta ca o fortificație modernă care îți oferă toate resursele premium de care ai nevoie, dar și un ansamblu de elemente de securitate pentru ca afacerea ta, dar și datele utilizatorilor tăi să rămână în siguranță.'
                                  text4='Spre deosebire de Gazduirea Web normală, aceste pachete au resurse de procesare (CPU) și RAM mai generoase, astfel încât un magazin online dezvoltat cu Woocommerce să aibă performanțe mai bune. Acest lucru nu înseamnă că nu poți instala Wordpress pe pachetele din categoria Gazduire Web normală.'/>


            <LeftTextContentBlock title='Găzduire Prestashop'
                                  text1='Majoritatea antreprenorilor care își doresc să lanseze un magazin cu prezență online, au la dispoziție doar câteva opțiuni de top, printre care Prestashop, care poate ocupa foarte ușor poziția de vârf. Dacă intenționezi să îți lansezi un magazin online, Prestashop are toate resursele și îți poate oferi toate uneltele de care ai nevoie pentru a putea administra și scala o astfel de afacere.'
                                  text2='Prestashop este o platformă de dezvoltare foarte capabilă, potrivită pentru construcția magazinelor online. Îți pune la dispoziție o colecție de unelte și features, metode de plăți și o foarte bună ipostază de administrare.'
                                  text3='Toate aceste resurse au nevoie de un pachet de găzduire web dedicat. Sitebunker oferă găzduire Prestashop care, în primul rând, garantează un UpTime de 99.99%. Fiecare pachet conține resurse necesare, soluții de securitate adiționale și găzduire pe server Litespeed. Magazinul online trebuie să se încarce extrem de repede, să nu aibă probleme de conexiune și să fie securizat ca un bunker împotriva atacurilor DDoS sau a tentativelor de furt de date.'/>


            <LeftTextContentBlock title='Găzduire Magento'
                                  text1='Magento a fost dezvoltată pentru ca utilizatorii care lansează magazine online să poată administra cât mai ușor toate procesele interne și externe ale unei astfel de afaceri. '
                                  text2='Sitebunker oferă pachete foarte avantajoase pentru cei care au nevoie de resurse care să susțină un astfel de magazin online. Cel mai avantajos pachet oferit de noi include stocare pe SSD NVMe, număr nelimitat de domenii și adrese de email, acces la SSH prin cPanel, LiteMage Cache, Protecție DDoS. Dacă ai nevoie de un pachet custom, poți oricând să modifici pachetul, să ne contactezi sau să suplimentezi resursele deja existente în platformă.'/>

            <FreeHostingHome title='Alege-ți planul de găzduire web și ai un domeniu gratuit'
                             text1='La SiteBunker ai domeniu .ro sau .eu gratuit pentru 1 an la abonamentele de web hosting selectate pentru plata pe minim 1 an. Descoperă acum soluțiile eficiente de Găzduire Web Premium și vezi ce se potrivește nevoilor tale. Descoperă acum soluțiile eficiente de Găzduire Web Premium și vezi ce se potrivește nevoilor tale.'
                             text2='Oferta este valabilă pentru pachetele: Bussines, Enterprise, Start Plus și
                            Enterprise Plus din categoriile: Gazduire Web, WordPress, Prestashop si Magento. Gratuitatea
                            nu se aplică pachetului Start și pachetelor destinate reseller-ilor.'/>


            <SecurityAndBackUp/>

            <CustomerSupportIndex/>

            <LeftTextContentBlock title='Beneficiile unui serviciu de Asistență Tehnică 24/7'
                                  uList={['Poți comunica oricând cu unul dintre sysadminii noștri.', 'Nu vei mai aștepta mult pentru rezolva problemelor.', 'Problemele tehnice pot fi rezolvate și în timpul nopții sau la telefon.', 'Serviciul de găzduire web poate primi asistență tehnică la orice oră.', 'Afacerea ta este mult mai în siguranță.', 'Sitebunker deține 2 datacentere proprii, deci intervențiile tehnice se întâmplă în câteva minute și sunt realizate direct de noi.', 'Primești asistență chiar dacă problema nu este pornită din serviciul nostru de găzduire.']}/>


            <div className="remove-top-padding">
                <InfoBlocks/>
            </div>

            <div className="grey-background">
                <OurBenefits/>
                <AboutUs2/>
                <ClientFeedback
                    title={"Ce spun clienții SiteBunker despre serviciile de "}
                    bold={"găzduire web oferite?"}/>
            </div>


            <Footer/>
        </Layout>
    )
}

export default Home
