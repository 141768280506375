import React from "react"
import "../../assets/css/HowToBuyWebHosting/HowToBuyWebHosting.css"

const HowToBuyWebHosting = () => {
    return (
        <section id="how-to-buy-web-hosting">
            <div className="container">
                <div className="row">
                    <div className="col-12 info-text">
                        <h2 className="mb-5 h3-title font-weight-bolder">Cum cumperi Găzduire Web în câțiva
                            pași simpli</h2>
                        <p>Să beneficiezi de un pachet de găzduire web la Sitebunker este extrem
                            de simplu. Cel mai
                            ieftin pachet de hosting web începe de la 2.90 euro pe luna, iar pentru a-ți putea
                            înregistra un domeniu, nu trebuie să faci altceva decât să alegi un pachet de găzduire și să
                            urmărești în continuare paginile în care vei fi redirecționat. </p>
                        <p>Urmează pașii de mai jos și vei putea beneficia și tu de unul dintre
                            cele mai bune servicii
                            de găzduire web oferite de noi. Te încurajăm să analizezi cu atenție facilitățile oferite de
                            toate pachetele, deoarece la prețuri foarte bune, primești mai multe niveluri de securitate,
                            servere din ce în ce mai performante, protecție și chiar domenii gratuite.
                        </p>

                        <ol className="text-left">
                            <li>Intră în secțiunea Găzduire Web sau Gazduire Premium din meniul
                                principal.
                            </li>
                            <li>Alege unul dintre pachetele disponibile.</li>
                            <li>Alege un nume de domeniu, verifică dacă este disponibil, alege
                                TLD-ul (.ro/.com, etc).
                            </li>

                            <p className="my-2 font-weight-bold">În pasul 3 vei putea să folosești platforma Sitebunker și pentru a
                                transfera domenii de la alt registrar, dar și să folosești un domeniu pe care deja îl
                                deții.</p>

                            <li>Configurează abonamentul. Alege dacă plata va fi făcută lunar,
                                trimestrial, anual sau în
                                funcție de preferințe, poți achita și la fiecare trei ani.
                            </li>
                            <li>Alege dacă îți dorești IP dedicat.</li>
                            <li> Alege dacă plătești pe persoană fizică sau juridică, introdu
                                CNP-ul, iar de la un caz
                                la altul, introdu și Numărul de Identificare Fiscală.
                            </li>
                            <li>Alege moneda, completează cu datele de identificare, alege metoda
                                de plată, fii de acord
                                cu termenii și condițiile.
                            </li>
                            <li>Felicitări, ai ales Găzduire Web de la Sitebunker.</li>
                        </ol>


                        <ul className="text-left">

                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowToBuyWebHosting



