import React, {Component} from "react"
import {CopyToClipboard} from "react-copy-to-clipboard"
import ReactTooltip from "react-tooltip"
import {Link} from "gatsby"

class PackagesIntro extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isTry: false,
            isPromo: false,
            promoCode: "JV89GSYI7K",
            copied: false
        }
    }

    showPromoCode(type) {
        if (type === 'trial') {
            this.setState({isTry: !this.state.isTry})
            this.setState({isPromo: false})
        } else if ('promo') {
            this.setState({isPromo: !this.state.isPromo})
            this.setState({isTry: false})
        }
    }

    render() {
        const {isTry, isPromo, promoCode} = this.state
        return (
            <section id="packages-intro">
                <div className="promo-title d-flex justify-content-center">
                    <p>
                        <span className="accent-bg text-white">Promo:</span> Domeniu
                        <span className="second-accent-color ml-2">.ro</span> sau<span
                        className="second-accent-color ml-2">.eu</span> gratuit la abonamentele de găzduire web selectate, pentru plata pe minim 1 an <i
                        className="ml-1 bx bxs-message-rounded-error accent-color"
                        data-for="promo-message"
                        data-place="top"
                        data-tip="Oferta este valabilă pentru pachetele achizitionate pe cel puțin 1 an: Business, Enterprise, Start Plus și Enterprise Plus din categoriile: Gazduire Web, WordPress, Prestashop si Magento."
                        data-effect="solid"
                    />
                        <ReactTooltip backgroundColor='black' type="dark" id="promo-message"/>
                    </p>
                </div>

                <div className="packages-area container-fluid pt-100">
                    <div className="container">

                        <div className="section-title section-title-800">
                            <h2 className="text-white h3-title">Găzduire Web cu migrare gratuită și fără griji
                                <i className="ml-1 bx bxs-message-rounded-error accent-color heartbeat"
                                   data-for="info-message"
                                   data-place="top"
                                   data-tip="Daca vechiul dvs provider de hosting foloseste cPanel. *Migrarile se fac in intervalul Luni - Vineri 9-23"
                                   data-effect="solid"
                                />
                            </h2>
                            <ReactTooltip backgroundColor='black' type="dark" id="info-message"/>
                            <p className="text-white">Ești în căutarea unui hosting provider din România care să-ți
                                ofere servicii de găzduire web eficiente? La SiteBunker ai migrarea asigurată și 30 de
                                zile să testezi beneficiile.</p>
                        </div>

                        <div className="buttons-row row mb-5">
                            <div className="col-lg-6 col-md-12 text-right mb-3">
                                <a className="accent-btn-standard d-inline-flex align-items-center w-100 justify-content-center"
                                   style={{cursor: 'pointer'}}
                                   onClick={() => this.showPromoCode('trial')}>Economisești 1 lună / plata pe 1 an <i
                                    className="bx bx-right-arrow-alt ml-1"/></a>
                            </div>

                            <div className="col-lg-6 col-md-12 text-left mb-3">
                                <a className="sm-white-btn-outline d-inline-flex align-items-center w-100 justify-content-center"
                                   onClick={() => this.showPromoCode('promo')}>Încearcă 1 lună
                                    GRATUIT <i className="bx bx-right-arrow-alt ml-1"/></a>
                            </div>
                        </div>

                        {/*Promo Code*/}
                        <div className={`row ${isPromo ? "d-block" : "d-none"}`}>
                            <div className="col-12">
                                <div className="promo text-white text-center mb-5">
                                    Alege pachetul de web hosting preferat, urmează pașii de comandă, selectează plata
                                    lunară și nu uita să folosești codul promoțional
                                    <span className="promo-code mx-2">
                                        <CopyToClipboard text={promoCode}>

                                            <span data-tip="Copiat!"
                                                  data-text-color="white"
                                                  data-background-color="#17992C"
                                                  data-place="top"
                                                  data-effect="solid"
                                                  data-event="click"
                                                  data-iscapture={true}
                                                  data-for="promo-code"
                                            >
                                                {promoCode}
                                                <i className="bx bx-copy-alt"/>

                                            </span>
                                        </CopyToClipboard>

                                        <ReactTooltip id="promo-code"/>

                                    </span>
                                    înainte de a finaliza comanda. Atât de simplu este să te bucuri de 1 lună GRATUITĂ.
                                </div>
                            </div>
                        </div>


                        {/*Promo Text*/}
                        <div className={`row ${isTry ? "d-block" : "d-none"}`}>
                            <div className="col-12">
                                <div className="promo text-white text-center mb-5">
                                    Vei economisi: 1 lună la plata pe 1 an, 3 luni la plata pe 2 ani, 5 luni la plata
                                    pe 3 ani.
                                    Nu uita, beneficiezi și de domeniu .ro sau .eu gratuit la abonamentele de
                                    web hosting selectate, pentru plata pe minim 1 an.

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default PackagesIntro
