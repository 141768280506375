import React from "react"
import techImg from "../../assets/images/new-imgs/technical-assistance.png"
import {Link} from "gatsby"
import '/src/assets/css/CustomerSupportIndex/customer-support-index.css'

const CustomerSupportIndex = () => {


    return (
        <section id="customer-support">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 position-relative">
                        <div className="svg-img">
                            <img src={techImg} alt="Gazduire Web" loading="lazy"/>
                        </div>
                    </div>
                    <div className="col-md-6 info-text">
                        <h2 className="mb-5 h3-title">Asistența tehnică <span>24/7 Inclusă</span></h2>
                        <p>Suntem prompți în orice situație și soluționăm rapid provocările tehnice legate de găzduirea
                            web aleasă. </p>
                        <p className="mb-5"> Poți intra în legătură cu echipa SiteBunker foarte facil, la orice oră din
                            zi sau din noapte, chiar și pentru o întrebare. Răspundem într-un timp scurt.</p>
                        <Link to="/contact/" className="view-more-button">Testează-ne disponibiliatea</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CustomerSupportIndex;
