import React from "react"
import {Link} from "gatsby"
import ReactTooltip from "react-tooltip"

import '/src/assets/css/Homepage/our-packages.css'

import webSSDImage from "../../assets/images/home-img/web.png"
import vpsImage from "../../assets/images/home-img/vps-server-icon.png"
import prestashopImage from "../../assets/images/home-img/presta.png"
import wordpressImage from "../../assets/images/home-img/wordpress.png"
import redisImage from "../../assets/images/mini-imgs/redis-icon.png"
import redisGreyImage from "../../assets/images/mini-imgs/redis-grey.png"


const OurPackages = () => {
    return (
        <section id="home-our-packages">

            <ReactTooltip backgroundColor="black" type="dark" id="tooltip-homepage"/>

            <div className="row packages-row">

                <div className="col-lg-3 col-md-6 mb-md-3 single-package position-relative">
                    <div className="package-block">
                        <div className="row title-and-price align-items-center">
                            <div className="col-md-12">
                                <h3>Găzduire <br/>
                                    Web SSD</h3>
                            </div>
                            <div className="col-md-12">
                                <p className="align-items-center mt-3">
                                    <span>de la </span>
                                    <span className='price-tag'>2.90 €</span>
                                    <span>/lună</span>
                                </p>
                            </div>
                        </div>


                        <Link to="/gazduire-web-ssd/" className="more-package-btn">Vezi pachetele</Link>


                        <div className="package-img">
                            <img src={webSSDImage} alt="Web SSD" lazy="true"/>
                        </div>


                    </div>
                    <div className="home-package-feature">
                        <ul>
                            <li><i className="flaticon-tick"/>LiteSpeed Webserver<i
                                className="bx bx-info-circle ml-1 font-weight-light"
                                data-for="tooltip-homepage" data-place="top"
                                data-tip="LiteSpeed asigura incarcarea rapida a website-ului tau"
                                data-effect="solid"/></li>

                            <li><i className="flaticon-tick"/>Protectie DDoS<i
                                className="bx bx-info-circle ml-1 font-weight-light"
                                data-for="tooltip-homepage" data-place="top"
                                data-tip="Prin GTS(Arbor) & Voxility"
                                data-effect="solid"/></li>

                            <li><i className="flaticon-tick"/>NodeJS Selector</li>
                        </ul>

                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-md-3 single-package position-relative">
                    <div className="package-block">
                        <div className="row title-and-price align-items-center">
                            <div className="col-md-12">
                                <h3>Găzduire <br/>
                                    WordPress SSD</h3>
                            </div>
                            <div className="col-md-12">
                                <p className="align-items-center mt-3">
                                    <span>de la </span>
                                    <span className='price-tag'>9.90 €</span>
                                    <span>/lună</span>
                                </p>
                            </div>
                        </div>


                        <Link to="/gazduire-wordpress-ssd/" className="more-package-btn">Vezi pachetele</Link>


                        <div className="package-img">
                            <img src={wordpressImage} alt="Wordpress" lazy="true"/>
                        </div>


                    </div>
                    <div className="home-package-feature">
                        <ul>
                            <li><i className="flaticon-tick"/> <span className='free-featured'>GRATIS</span> Tema
                                Premium Wordpress<a
                                    className="bx bx-info-circle ml-1 font-weight-light"
                                    data-for="tooltip-homepage" data-place="top"
                                    href={"/ai-teme-word-press-premium-gratuite-incluse-in-pachetele-de-gazduire/"}
                                    data-tip="Disponibil la plata anuala. Click pentru detalii."
                                    data-effect="solid"/></li>

                            <li><i className="flaticon-tick"/>LiteSpeed WP Cache<i
                                className="bx bx-info-circle ml-1 font-weight-light"
                                data-for="tooltip-homepage" data-place="top"
                                data-tip="LiteSpeed asigura incarcarea rapida a website-ului tau"
                                data-effect="solid"/></li>

                            <li>
                                <img className="redis-icon" src={redisImage} alt="Redis Icon" lazy="true"/>
                                Redis Cache
                            </li>
                        </ul>

                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-md-3 single-package position-relative">
                    <div className="package-block">
                        <div className="row title-and-price align-items-center">
                            <div className="col-md-12">
                                <h3>Găzduire <br/>
                                    PrestaShop SSD</h3>
                            </div>
                            <div className="col-md-12">
                                <p className="align-items-center mt-3">
                                    <span>de la </span>
                                    <span className='price-tag'>15.90 €</span>
                                    <span>/lună</span>
                                </p>
                            </div>
                        </div>


                        <Link to="/gazduire-prestashop-ssd/" className="more-package-btn">Vezi pachetele</Link>


                        <div className="package-img">
                            <img src={prestashopImage} alt="Prestashop" lazy="true"/>
                        </div>


                    </div>
                    <div className="home-package-feature">
                        <ul>

                            <li><i className="flaticon-tick"/> <span className='free-featured'>GRATIS</span> Modul
                                Premium Prestashop<a
                                    className="bx bx-info-circle ml-1 font-weight-light"
                                    data-for="tooltip-homepage" data-place="top"
                                    data-tip="Disponibil la plata anuala. Click pentru detalii."
                                    href={"/primesti-gratis-modul-presta-shop-premium-la-pachetele-de-hosting-selectate/"}
                                    data-effect="solid"/></li>

                            <li><i className="flaticon-tick"/>LiteSpeed PrestaShop Cache<i
                                className="bx bx-info-circle ml-1 font-weight-light"
                                data-for="tooltip-homepage" data-place="top"
                                data-tip="LiteSpeed asigura incarcarea rapida a website-ului tau"
                                data-effect="solid"/></li>

                            <li><i className="flaticon-tick"/>Support 24/7</li>
                        </ul>

                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-md-3 single-package position-relative">
                    <div className="package-block">
                        <div className="row title-and-price align-items-center">
                            <div className="col-md-12">
                                <h3>Servere <br/>
                                    VPS</h3>
                            </div>
                            <div className="col-md-12">
                                <p className="align-items-center mt-3">
                                    <span>de la </span>
                                    <span className='price-tag'>5 €</span>
                                    <span>/lună</span>
                                </p>
                            </div>
                        </div>


                        <Link to="/servere-vps/" className="more-package-btn">Vezi pachetele</Link>


                        <div className="package-img">
                            <img src={vpsImage} alt="Prestashop" lazy="true"/>
                        </div>


                    </div>
                    <div className="home-package-feature">
                        <ul>

                            <li><i className="flaticon-tick"/>Stocare NVMe SSD</li>

                            <li><i className="flaticon-tick"/>Trafic Nelimitat</li>

                            <li><i className="flaticon-tick"/>Protectie DDoS</li>
                        </ul>

                    </div>
                </div>

            </div>

        </section>
    )
}

export default OurPackages
