import React from "react"
import aboutUsImg from "../../assets/images/new-imgs/site-home2-2.png"
import '/src/assets/css/AboutUs/about-us.css'


const AboutUs = () => {
    return (
        <section id="about-us">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 about-us-text">
                        <h2 className="mb-5 h3-title">Succesul tău online începe cu o găzduire web eficientă! </h2>
                        <p>La SiteBunker înțelegem importanța unui hosting de încredere și impactul acestuia asupra
                            succesului tău
                            online.</p>
                        <p>Indiferent de pachetul de care ai nevoie îți oferim soluții de găzduire web eficiente care
                            să-ți susțină
                            activitatea.</p>
                    </div>
                    <div className="col-md-6 position-relative">
                        <div className="svg-img">
                            <img src={aboutUsImg} alt="Hosting Romania" loading="lazy"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs
