import React from "react"
import { Link } from "gatsby"
import aboutUs from "../../assets/images/new-imgs/about-us-2.png"


const AboutUs2 = () => {
  return (

    <section id="about-us-2">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 info-text">
            <h2 className="h3-title mb-5"><strong>SiteBunker</strong> - PROVIDER HOSTING ROMÂNIA CU SOLUȚII COMPLETE
            </h2>
            <p>Pachetele disponibile de găzduire web nu conțin în totalitate resursele necesare nevoilor tale? Cauți
              suport inclusiv pentru configurarea VPS-ului? Este suficient să ne contactezi.</p>
            <p>Echipa SiteBunker este pregătită pentru orice tip proiect, oricât de ambițios ar fi acesta. În plus,
              suntem alături de tine atunci când site-ul tău va scala la următorul nivel cu cele mai potrivite opțiuni
              și sfaturi dedicate obiectivelor tale.</p>
            <Link to="/contact/" className="view-more-button">CONTACTEAZĂ-NE</Link>
          </div>
          <div className="col-md-6 position-relative">
            <div className="svg-img">
              <img src={aboutUs} alt="Hosting Romania" loading="lazy"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs2
