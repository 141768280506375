import React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";
import '/src/assets/css/SSLFaqs/sslFaqs.css'


const SSLFaqs = () => {
    return (
        <section id="ssl-faqs">
            <div className="container">
                <h2 className="h3-title main-color font-weight-bolder mb-4">SSL - Întrebări Frecvente</h2>
                <div className="faq-accordion">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Ce este un certificat SSL?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Un certificat SSL sau un certificat Secure Sockets Layer este un certificat digital
                                    al cărui scop este de a proteja toate informațiile care se transmit între un website
                                    și utilizatorul acestuia. În timpul navigării unui website, fiecare utilizator face
                                    un schimb de informație cu website-ul. Această informație poate fi furată, folosită
                                    sau alterată dacă nu este protejată.
                                </p>

                                <p>
                                    Certificatul SSL are acest rol de a crea o legătură sigură între website și
                                    utilizator. Practic, este un paravan de protecție și criptare care menține un nivel
                                    de securitate optim. Datele și informațiile sunt în siguranță, private și nu pot fi
                                    alterate în timpul procesului.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    De ce ai nevoie de un certificat SSL?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Pentru a putea construi încredere între afacere și utilizator. În prezent,
                                    certificatul SSL se află printre primele caracteristici care dictează posibila
                                    conexiune cu orice afacere sau website pe care îl vizităm zilnic. Pentru că
                                    securitatea este cel mai important factor în prezent, doar simpla lipsă a unui
                                    certificat SSL te cataloghează ca fiind vulnerabil, iar încrederea în afacerea ta
                                    este redusă cu până la 100%.
                                </p>

                                <p>
                                    Nu vei putea accepta plăți cu cardul bancar deoarece unii procesatori de plăți au
                                    această cerință, ca website-ul să aibă un certificat SSL. Chiar dacă nu îți este
                                    impus acest lucru, este cu atât mai mult recomandat să îți securizezi website-ul,
                                    deoarece un furt de date bancare îți poate aduce probleme de natură penală.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Cum activezi certificatul SSL?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    După ce ai finalizat achiziția unui pachet de găzduire web, în termen de 4 ore,
                                    certificatul SSL se va activa automat. Cronometrul celor patru ore pornește din
                                    momentul în care se finalizează propagarea nameserverelor și a IP-ului în internet.
                                </p>

                                <p>
                                    În cazuri excepționale, dacă activarea nu se realizează automat, utilizatorul are la
                                    dispoziție două metode eficiente și rapide prin care poate activa chiar singur
                                    certificatul, în doar câteva minute.
                                </p>

                                <ol>
                                    <li>Accesează cPanel, iar din secțiunea “SSL/TLS Status”, rulează AutoSSL.</li>
                                    <li>Instalează un modul dedicat pentru activarea SSL. “Really Simple SSL” poate fi
                                        rulat din CMS.
                                    </li>
                                </ol>
                            </AccordionItemPanel>
                        </AccordionItem>

                    </Accordion>
                </div>
            </div>
        </section>
    )
}


export default SSLFaqs