import React from "react"
import antivirusIcon from "../../assets/images/sistem-antivirus.png"
import notifIcon from "../../assets/images/sistem-notificari.png"
import servIcon from "../../assets/images/servicii-web.png"
import customerIcon from "../../assets/images/customer-support-icon.png"
import { Link } from "gatsby"

const OurBenefits = () => {
  return (
    <section id="our-benefits">
      <div className="container">
        <div className="title-row pb-70">
          <h2 className="h3-title text-center mb-4">Redefinește experiența ta de găzduire cu <strong>SiteBunker</strong>
          </h2>
          <h3 className="h3-title text-center"><em>Iată prin ce ne diferențiem:</em></h3>
        </div>
        <div className="row">
          <div className="col-md-6 benefit-col">
            <div className="row">
              <div className="col-md-12 col-lg-3">
                <img src={antivirusIcon} loading="lazy" alt="" />
              </div>
              <div className="col-md-12 col-lg-9">
                <h4>SECURITATE</h4>
                <p>Oferim mai mult decât găzduire web. Ne ocupăm inclusiv de protecție. Securitatea serviciilor găzduire
                  web SiteBunker este îmbunătățită constant, inclusiv pentru atacurile de tip DDoS. </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 benefit-col">
            <div className="row">
              <div className="col-md-12 col-lg-3">
                <img src={notifIcon} loading="lazy"  alt="" />
              </div>
              <div className="col-md-12 col-lg-9">
                <h4>TRANSPARENȚĂ</h4>
                <p>Vei fi în permanență ținut la curent cu orice îți influențează site-ul. SiteBunker dispune de un
                  sistem de notificări în real-time și poți verifica inclusiv statusul serviciilor alese chiar <Link
                    to="/status/">aici.</Link></p>
              </div>
            </div>
          </div>
          <div className="col-md-6 benefit-col">
            <div className="row">
              <div className="col-md-12 col-lg-3">
                <img src={servIcon} loading="lazy"  alt="" />
              </div>
              <div className="col-md-12 col-lg-9">
                <h4>PARTENERIAT</h4>
                <p>Site-ul tău a ajuns pe mâini capabile. Suntem o echipă de experți IT pasionați de întreaga sferă de
                  servicii web. Tu doar comunică-ne nevoile tale și găsim soluții eficiente pentru tine.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 benefit-col">
            <div className="row">
              <div className="col-md-12 col-lg-3">
                <img src={customerIcon} loading="lazy" alt="" />
              </div>
              <div className="col-md-12 col-lg-9">
                <h4>PROMPTITUDINE</h4>
                <p>Echipa Suport SiteBunker este gata să rezolve orice provocare tehnică pe care o întâmpini, la orice
                  oră din zi sau din noapte, 365 de zile pe an. Contactează-ne și răspundem imediat.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurBenefits
