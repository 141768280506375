import React, {useState} from 'react';
import './Feedback.style.css';


const Feedback = (props) => {

    const [arata, setArata] = useState(props.stare)


    const closeHandler = () => {
        setArata(false)
        props.inchide(arata)
    }

    if (props.available === "available") {
        return (
            <div className="alert alert-success d-flex flex-grow-1 justify-content-between align-items-center"
                 role="alert">

                <p style={{color: 'black'}} className='m-0 text-left'>Domeniul este disponibil.
                    <a className='font-weight-bold ml-1'
                       href={`https://sitebunker.ro/client/cart.php?a=add&domain=register&domains[]=${props.domeniu}&1${props.domeniu}=1&language=romanian&currency=2`}
                       target="_blank">Comandă!</a>
                </p>

                <span onClick={closeHandler} className='close important'>x</span>
            </div>


        )
    }


    if (props.available === "unavailable") {
        return (
            <>
                {props.type === "Transfer" ?
                    <div className="alert alert-success d-flex flex-grow-1 justify-content-between align-items-center"
                         role="alert">
                        <p className='m-0 text-left' style={{color: 'black'}}>Domeniul poate fi transferat.
                            <a className='font-weight-bold ml-1'
                               href={`https://sitebunker.ro/client/cart.php?a=add&domain=transfer&domains[]=${props.domeniu}&1${props.domeniu}=1&language=romanian&currency=2`}
                               target="_blank">Transferă!
                            </a>
                        </p>
                        <span onClick={closeHandler} className='close important'>x</span>
                    </div>
                    :
                    <div
                        className="alert alert-danger d-flex flex-grow-1 justify-content-between align-items-center"
                        role="alert">
                        <p className='m-0 text-left' style={{color: 'black'}}>Domeniul nu este disponibil </p>
                        <span onClick={closeHandler} className='close important'>x</span>
                    </div>
                }

            </>

        )
    } else {
        return null
    }

}

export default Feedback;
