import React from "react";
import '/src/assets/css/SecirityAndBackUp/security-and-backup.css'

const SecurityAndBackUp = () => {
    return (
        <section id="security-and-backup">
            <div className="container">
                <div className="row">
                    <div className="col-12 info-text">
                        <h2 className="mb-5 h3-title font-weight-bolder">Securitate și măsuri de backup ale serverelor
                            Sitebunker</h2>
                        <p>Termenul de bunker nu este o întâmplare, ci are legătură directă cu modul în care am
                            securizat întreg ansamblul de servere. Un update major în acest sens a avut loc pe tot
                            parcursul anului 2021, fiind singurii provideri de hosting din România ce folosesc protecție
                            DDoS de la 2 furnizori independenți de TOP, astfel încât să asigurăm redundanță pentru tot
                            ceea ce înseamnă filtrare DDoS.</p>

                        <p>Un server redundant este un server care, indiferent de ceea ce se întâmplă, are o măsură de
                            siguranță care garantează că sistemul va rămâne funcțional. Folosind surse de alimentare
                            redundante și configurație storage în RAID 10.</p>

                        <p>Practic, serverele noastre au măsuri de backup pentru măsurile de backup. Iar acest principiu
                            se aplică pentru toate sistemele hardware Sitebunker.</p>

                        <ul>
                            <li>Sysadminii sunt singurii care au acces în camera serverelor. Accesul se face pe bază de
                                cartelă electronică, iar nivelul de igienă este pe cât de strict se poate.
                            </li>
                            <li>Atât interiorul camerei serverelor, dar și exteriorul acesteia sunt monitorizate de
                                camere de supraveghere
                            </li>
                            <li>Răcirea serverelor respectă și ea un standard de redundanță ridicat fiind asigurată de
                                un sistem <strong>In Row N+1</strong>.
                            </li>
                            <li>În eventualitatea unei căderi de curent sau a unei întreruperi de energie, serverele vor
                                continua să funcționeze cu ajutorul unui ansamblu extrem de puternic format din <strong>
                                    2 generatoare Energo și Senci ce dezvoltă împreună un total de 300 kVa.
                                </strong>
                            </li>
                            <li>În cazul unui incendiu, sistemul <strong>Inergen</strong> este
                                capabil să detecteze foarte rapid semnele
                                unui astfel de incident și acționează eficient pentru stingerea acestuia.
                            </li>
                            <li>Redundanța trebuie păstrată la un nivel extrem de ridicat în privința conexiunii la
                                internet. Iar pentru acest lucru avem mai multe soluții și colaborări: <strong>4 Trasee
                                    diferite
                                    de fibră optică, Peerings directe cu Voxility, GTS, RDS și Telekom în datacenter
                                    propriu
                                    și echipamente proprii.</strong>
                            </li>
                            <li>Nivel crescut de redundanță asigurat de:
                                - Routere Cisco legate în HA / Switch-uri de acces pentru servere / Transport Layer 2
                                propriu între cele 2 datacentere cu o soluție de backup prin antenă radio între cele
                                două locații. / UPS-uri APC și EATON rackabile de câte 5 kw fiecare / Backup-uri
                                regulate asigurate prin NAS-uri Qnap rackabile.
                            </li>
                            <li>Serverele Sitebunker sunt HPe Gen10 și Supermicro de ultimă generație.</li>
                            <li>Rack-uri Sharck de 47U și Eaton de 42U cu uși ventilate, foarte rezistente.</li>
                        </ul>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default SecurityAndBackUp
