import React from "react"
import logoLitespeed from "../../assets/images/logo-litespeed.png"
import bannerImg from "../../assets/images/new-imgs/home-banner.png"

const Banner = () => {
  return (
    <div className="it-services-banner">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="main-banner-content">
                <h1>Găzduire Web Premium</h1>
                <p className="mb-0">Soluții găzduire web eficiente de la 2.90 euro/lună.</p>
                <p>Servere localizate in Romania, Europa Centrala și de Vest.</p>
              <div className="light-speed-img">
                  <img src={logoLitespeed} alt="banner" loading="lazy" />
                </div>
                <p><strong>Testează GRATUIT 1 LUNĂ de găzduire web pentru site-ul tău. </strong>
                  Alege pachetul potrivit și convinge-te de performanțele SiteBunker.</p>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
              <div className="main-banner-image">
                <img src={bannerImg} alt="Gazduire Web" loading="lazy" className='img-fluid' />
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
